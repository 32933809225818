export default {
    methods: {
        removeSelf(arrayOfObjects) {
            return arrayOfObjects.filter((each) => each.email !== this.$store.state.user.data.email);
        },
        removeUsers(arrayOfObjects, toExclude) {
            return arrayOfObjects.filter((each) => !toExclude.includes(each.employeeId));
        },
    },
};
