<template>
    <NebulaPageWrapper
        class="employee-dashboard-container"
        padded
    >
        <NebulaGridRow gutter>
            <NebulaGridBlock
                v-bind="profileAttrs"
                class="connector-profile__profile-card"
            >
                <EmployeeProfile
                    :loading="loading.profile"
                    :data="employeeData"
                    :selfView="selfView"
                />
            </NebulaGridBlock>
            <NebulaGridBlock
                :columns="6"
                :mdSize="8"
                v-if="!flags['calendar-removed']"
                class="connector-profile__availability-card"
            >
                <Availability
                    v-if="employeeData"
                    :showColumn="false"
                    :employeeId="employeeId"
                />
            </NebulaGridBlock>
        </NebulaGridRow>

        <NebulaGridRow>
            <NebulaGridBlock :columns="12">
                <PageSection
                    class="page-section--profile-cards"
                    :type="relatedConnectors.length > 0 ? 'flat' : 'content-raised'"
                    title="Related Connectors"
                    :viewAll="{ show: true, link: orgMembersLink }"
                >
                    <CardBlock
                        v-if="relatedConnectors && relatedConnectors.length > 0"
                        :cardList="relatedConnectors"
                        :cardCount="4"
                        cardType="profile"
                        :loading="loading.relatedConnectors"
                    />
                    <EmptyState
                        v-else
                        icon="users"
                        title="Looks like we don't have related Connectors yet."
                        description="Active employees will appear here."
                    />
                </PageSection>
            </NebulaGridBlock>
        </NebulaGridRow>
        <OrgResourcesRow
            v-if="organizationId"
            v-bind="{ selfView }"
            :category="organizationId"
        />
    </NebulaPageWrapper>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';
import Availability from '@/components/Profile/Availability.vue';
import CardBlock from '@/components/shared/cards/CardBlock.vue';
import EmployeeProfile from '@/components/dashboard/EmployeeProfile.vue';
import EmptyState from '@/components/shared/layout/EmptyState.vue';
import PageSection from '@/components/shared/layout/PageSection.vue';
import OrgResourcesRow from '@/components/Profile/shared/OrgResourcesRow.vue';

import scrollToTop from '@/mixins/scrollToTop';
import { resourceCardsData } from '@/data/resourceCards';

import createPayload from '@/mixins/data/createPayload';
import removeSelf from '@/mixins/removeSelf';

export default {
    name: 'EmployeeProfilePublic',
    components: {
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        Availability,
        CardBlock,
        EmployeeProfile,
        EmptyState,
        PageSection,
        OrgResourcesRow,
    },
    mixins: [createPayload, removeSelf, scrollToTop],
    data() {
        return {
            resourceCardsData,
            orgMembersLink: undefined,
            organizationId: undefined,
            loading: {
                relatedConnectors: true,
                profile: true,
            },
        };
    },
    computed: {
        employeeId() {
            return this.$route.params.id;
        },
        employeeData() {
            return this.$store.state.page.employee.data;
        },
        selfView() {
            if (!this.$store.state.page.employee.data) {
                return false;
            }
            return this.$store.state.page.employee.data.employeeId === this.$store.state.user.data.employeeId;
        },
        relatedConnectors() {
            const cleaned = this.removeUsers(this.$store.state.page.employees, [this.employeeId]);
            return cleaned.slice(0, 4);
        },
        flags() {
            return this.$store.getters.flags;
        },
        profileAttrs() {
            let attrs = {
                columns: 6,
                mdSize: 8,
            };
            if (this.flags['calendar-removed']) {
                attrs = {
                    columns: 8,
                    offset: 2,
                    mdOffset: 0,
                };
            }

            return attrs;
        },
    },
    methods: {
        async loadData() {
            const payload = await this.createPayload({ employeeId: this.employeeId });
            await this.$store.dispatch('viewEmployee', payload);
            this.loading.profile = false;

            const employeeOrgs = this.$store.getters.profileEmployeeOrgs.map((each) => each.organization_id);

            // TODO update this to filter multiple orgs if educator
            const [firstOrg] = employeeOrgs;
            this.organizationId = firstOrg;
            this.orgMembersLink = `/${firstOrg}/connectors`;

            const toConvert = [];
            employeeOrgs.forEach((organizationId) => toConvert.push(['organization', organizationId]));
            toConvert.push(['limit', 5], ['sort', 'random']);
            const params = new URLSearchParams(toConvert).toString();
            const relatedPayload = await this.createPayload({ params });
            await this.$store.dispatch('getEmployees', relatedPayload);
            this.loading.relatedConnectors = false;
        },
    },
    async mounted() {
        this.scrollToTop();
        this.loadData();
    },
    watch: {
        employeeId() {
            this.scrollToTop();
            this.loadData();
        },
    },
    provide: {
        domainType: 'employee',
        pageType: 'profile',
    },
    beforeDestroy() {
        this.$store.dispatch('resetPageData');
    },
};
</script>

<style lang="stylus">
.connector-profile {
    &__profile-card {
        card-base();
    }
    &__availability-card {
        padded-card();
    }
}
</style>
